import {createApp} from 'vue'
import {createPinia, } from 'pinia'

// import the root component App from a single-file component.
import App from './App.vue'
import router from './router'

const site = createApp(App)
const pinia = createPinia()

site.use(router)
site.use(pinia)


site.mount('#app')