<script setup>
    import {inject,defineProps,defineEmits} from 'vue'
    import tablerow from '@/components/Fahrzeugverwaltung/components/TableRow.vue'

    const props = defineProps({name:String})
    defineEmits(['update'])

    const model = inject('selected')
    
    var empty = model.value != ""
</script>

<template>
    <div class="input center">
        <div v-if="empty" >
            <table>
                    <tablerow v-for="(value,key) in model" :key="key" :name="key" ></tablerow>
            </table>
            <button @click="$emit('update', model)">Update {{props.name}}</button>
        </div>
        <div v-else>
            Keine Auswahl vorhanden.
        </div>
    </div>
</template>