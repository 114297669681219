<script setup>
    import {inject,defineEmits} from "vue"
    import { useTokenStore} from '@/tokenStore'

    const selected = inject('selected');
    const api = inject('api')
    const tokenStore = useTokenStore()

    const emit = defineEmits(['refresh'])
    
    function deleteRecord()
    {
        console.log("ID Fuktion:" + selected.value);
        let request = new XMLHttpRequest();
        request.open("DELETE", "/api/" + api.value);
        request.setRequestHeader("Content-Type", "application/json");
        request.setRequestHeader("Authorization", "Bearer " + tokenStore.token)
        request.send(JSON.stringify(selected.value));

        request.onload = () => 
        {
            if(request.status === 200)
            {
                console.log(request.response);
                emit('refresh');
            }
        }


        
    }

</script>

<template>
    <button @click="deleteRecord()" ><slot></slot></button>
</template>