<script setup>
import {defineProps,ref,defineEmits} from "vue"
import {useTokenStore} from '@/tokenStore'
import router from "@/router";

const emits = defineEmits("refresh");
const props = defineProps({type:String, id:String,accepted:String,folder:String})
const file = ref();
var  form = new FormData();

const tokenStore = useTokenStore()

function uploadFile(){

    let request = new XMLHttpRequest();
    request.open("PUT", "/api/datei/" + props.folder + "/" + props.id);
    request.setRequestHeader("Authorization", "Bearer " + tokenStore.token);
    console.log(form.get("file"));
    console.log(file.value.files);
    request.send(form);
    
    request.onload = () => 
    {
        console.log(request.status)
        if(request.status === 204)
        {
            console.log(request.response)
            emits("refresh")
        }
        else if (request.status === 401) 
            {
                tokenStore.token = ""
                router.push("/login")
            }
        else
        {
            console.log(request.response)
            alert("Error in request")
        }
    }
}

function uploadFileChange(){
    form.append("id",props.id);
    form.append("type",props.type);
    form.append("file",file.value.files[0]);
    form.append("fileName",file.value.files[0].name)
}

</script>


<template>
<div>
    <input type="file" placeholder="Bild Hochladen" @change="uploadFileChange" ref="file" :accept="props.accepted">
    <button type="submit" @click="uploadFile()">Upload file</button>
</div>
</template>