<script setup>
    import { defineProps,defineEmits,inject} from 'vue'
    import {useTokenStore} from '@/tokenStore'
    /* eslint-disable */    
    const props = defineProps({object:Object})

    const tokenStore = useTokenStore()
    const api = inject('api')


    const emit = defineEmits(['refresh','new'])

    //onst benutzer = ref({""});
    //const rechnungen = ref({"betrag": 50, "grund": "tanken"});


    function setData(object)
    {
        console.log("create: " + JSON.stringify(object.value));
        let request = new XMLHttpRequest();
        request.open("POST", "/api/" + api.value + "/new");
        request.setRequestHeader("Content-Type", "application/json");
        request.setRequestHeader("Authorization", "Bearer " + tokenStore.token)
        request.send(JSON.stringify(object.value));
        emit('refresh');
    }

    defineExpose({
        setData
    })
</script>

<template>
    <button @click="$emit('new')">
        <slot></slot>
    </button>
</template>
