<script setup>
import {defineProps,ref,onMounted,defineEmits,inject} from 'vue';
import {useTokenStore} from '@/tokenStore'
import router from "@/router";
import FileUpload from './UploadFile.vue';

const props = defineProps({id:String})
const emit = defineEmits(['refresh'])
const api = inject("api")
const data = ref("")
const tokenStore = useTokenStore()
const model = ref("");

function getData()
    {   if (props.id != "")
        {
            model.value = ""
            let request = new XMLHttpRequest();
            console.log("api: " + api.value)
            request.open("GET", "/api/datei/" + api.value + "/" + props.id);
            request.setRequestHeader("Authorization", "Bearer " + tokenStore.token)
            request.send();
            request.onload = () => 
            {
                if(request.status === 200)
                {
                    data.value = JSON.parse(request.response);
                    model.value = data.value[0]
                }
                else if (request.status === 401) {
                    tokenStore.token = ""
                    router.push("/login")
                }
                else
                {
                    //data.value = ["error in request"];
                }
            }
        }
    }

    function deleteDatei(id){
        if (props.id != "")
        {
            let request = new XMLHttpRequest();
            request.open("DELETE", "/api/datei/" + id);
            request.setRequestHeader("Authorization", "Bearer " + tokenStore.token)
            request.send();
            request.onload = () => 
            {
                if(request.status === 200)
                {
                    console.log("dateien gelöscht")
                    getData()
                }
                else if (request.status === 401) {
                    tokenStore.token = ""
                    router.push("/login")
                }
                else
                {
                    //data.value = ["error in request"];
                }
            }
            //refresh()
        }
    }

    function downloadData(datei){
        let request = new XMLHttpRequest();
            request.open("GET", "/api/datei/" + datei.id);
            request.setRequestHeader("Authorization", "Bearer " + tokenStore.token)
            request.responseType = "blob"
            request.send();
            request.onload = () => 
            {
                if(request.status === 200)
                {
                    var blob = new Blob([request.response], {type: "octet/stream"})
                    if (window.navigator.msSaveOrOpenBlob) // IE10+
                        window.navigator.msSaveOrOpenBlob(blob, datei.name);
                    else { // Others
                        var a = document.createElement("a"),
                                url = URL.createObjectURL(blob);
                        a.href = url;
                        a.download = datei.name;
                        document.body.appendChild(a);
                        a.click();
                        setTimeout(function() {
                            document.body.removeChild(a);
                            window.URL.revokeObjectURL(url);  
                        }, 0); 
                    }

                    
                }
                else if (request.status === 401) {
                    tokenStore.token = ""
                    router.push("/login")
                }
                else
                {
                    data.value = ["error in request"];
                }
            }
            refresh()
    }

    function refresh(){
        getData()
        emit('refresh')

    }

    onMounted(() => {
        getData()
    })

</script>


<template>
<div>
    <table v-if="data!=''">
        <tr v-if="model.value!=''">
            <th>Name</th>
            <th>Donwloadlink</th>
        </tr>
        <tr v-for="(object) in data" :key="object.id" class="data-row" >
            <td >{{ object.name}}</td>
            <td ><button @click="downloadData(object)">Download</button><button @click="deleteDatei(object.id)">-</button></td>
            <!-- <td ><a :href="object.dateipfad" download>Download</a><button @click="deleteDatei(object.id)">-</button></td>-->
        </tr>
        <tr>
            <FileUpload :id="props.id" type="Fahrzeug" :folder="api.value"></FileUpload>
        </tr>
    </table>
    <div v-else>
        <FileUpload :id="props.id" type="Fahrzeug"></FileUpload>
    </div>
</div>
</template>