
<script setup>
    import { ref,computed } from 'vue'
    import { useTokenStore} from '@/tokenStore'
    import { useUserStore} from '@/userStore'
    import router from './router';
    
     /* eslint-disable */
    const emit = defineEmits(["selection"])
    const username = ref("")
    const password = ref("")
    const token = ref("")
    const tokenStore = useTokenStore()
    const userStore = useUserStore()

    const person = computed(() => 
    {
        return {"username": username.value, "password": password.value}
    })

    function login()
    {
        let request = new XMLHttpRequest();
        request.open("POST", "/api/login");
        request.setRequestHeader("Content-Type", "application/json");
        request.send(JSON.stringify(person.value));
        request.onload = () => 
        {
            if(request.status === 200 & request.response !== "User not found")
            {
                token.value = request.response;
                tokenStore.token = token;
                userStore.username = username
                emit("selection", 1)
                
                router.push("/overview")
            }
            else
            {
                alert("Error: User not found")
            }
        }

    }
</script>

<template>
    <div class="center">
        <div class="input">
            <div class="label"><label>Benutzername:</label><br/><label>passwort:</label></div>
            <div><input v-model="username" type="text" placeholder="username" @keyup.enter="login"><br/><input v-model="password" type="password" placeholder="password" @keyup.enter="login"></div>
        </div>
        
        <br/>
        <button @click="login" class="center">
            Login
        </button>
    </div>
</template>