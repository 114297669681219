<script setup>
    import {defineProps,inject} from 'vue'

    const props = defineProps(['name'])
    
    const model = inject("selected");    
</script>

<template>
    <tr v-if="props.name != 'id' && props.name != 'dateien' && props.name !='username'">
        <td>
            <label  class="label" > {{props.name}}:</label>
        </td>
        <td>
            <input v-model="model[props.name]" type="text">
            <div v-if="key.includes('datum') || key.includes('zeit')" class="container">
                <td><label  class="label" > {{key}}:</label></td>
                <td><input v-model="newObject[key]" type="date"></td>
            </div>
            <div v-else-if="key.includes('betrag') || key.includes('rate')" class="container">
                <td><label  class="label" > {{key}}:</label></td>
                <td><input v-model="newObject[key]" type="number"></td>
            </div>
            <div v-else class="container">
                <td><label  class="label" > {{key}}:</label></td>
                <td><input v-model="newObject[key]" type="text"></td>
            </div>
            
        </td>
    </tr>
</template>