<script setup>
    import {inject,ref,watchEffect,defineEmits} from 'vue';
    import tableComponent from "../Fahrzeugverwaltung/components/TableComponent.vue";
    import tableHeader from "../Fahrzeugverwaltung/components/TableHeader.vue";
    
    const emits = defineEmits(['selection','refresh'])
    const data = inject('data');
    console.log("data: " + data);
    const model = ref(data.value[0]);
    const elements = ref([])

    

    // eslint-disable-next-line
    watchEffect(() => {
        model.value = data.value[0]
    })

    function selection(index){
        console.log("Selection detected")
        emits("selection",index)
        for(let i=0; i < this.elements.length; i++){
            if(i != index){
                elements.value[i].uncheck()
            }
        }
    }

    
</script>

<template>
    <div v-if="data!='' && model!=undefined" class="table">
        <table>
            <tr v-if="model.value!=''">
            <td></td>
            <tableHeader>Nr</tableHeader>
            <tableHeader v-for="(value,key) in model" :key="key" :keyname="key">{{key}}</tableHeader>
            </tr>
            <tableComponent :ref="'elements'" @refresh="$emit('refresh')" @selection="selection(index)" v-for="(object,index) in data" :key="object.id" class="data-row" :id="object.id" :dateien="object.dateien" :index="index" :object="object"></tableComponent>
        </table>
    </div>
    <div v-else>
        Keine Daten vorhanden!
    </div>
    
</template>
