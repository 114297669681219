<script setup>
    import FahrzeugFenster from "./components/FahrzeugFenster.vue"
    import {onMounted,watchEffect,ref} from "vue"
    import { useTokenStore} from '@/tokenStore'
    import router from "@/router"

    const data = ref({})
    const benutzt = ref([])
    const werkstatt = ref([])
    const bestellt = ref([])
    const bereitschafft = ref([])
    const stati = ref([false,false,false,false])
    const FahrzeugKlassen = ref("")
    const InterfaceKlassen = ref("inactive")
    const tokenStore = useTokenStore()
    const fahrzeug = ref({status:"bereitschafft"})
    const addButton = ref("")
    const addMode = ref("addMode")
    // eslint-disable-next-line
    function getData()
    {
        

        let request = new XMLHttpRequest();
        request.open("GET", "/api/fahrzeug");
        request.setRequestHeader("Authorization", "Bearer " + tokenStore.token)
        request.send();
        request.onload = () => 
        {
            if(request.status === 200)
            {
                data.value = JSON.parse(request.response);
            }
            else if (request.status === 401) {
                tokenStore.token = ""
                router.push("/login")
            }
            else
            {
                data.value = ["error in request"];
            }
        }
    }

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    onMounted(async () => {
        await getData()
    })
    
    watchEffect(() => {
        if(data.value != '' && !(isEmpty(data.value))){
            for (const fahrzeug of data.value){
                switch(fahrzeug.status){
                    case "benutzung":
                        benutzt.value.push(fahrzeug)
                        stati.value[0] = true;
                        break
                    case "werkstatt":
                        werkstatt.value.push(fahrzeug)
                        stati.value[1] = true;
                        break
                    case "bestellt" :
                        bestellt.value.push(fahrzeug)
                        stati.value[2] = true;
                        break
                    case "bereitschafft":
                        bereitschafft.value.push(fahrzeug)
                        stati.value[3] = true;
                }
            }
        }
    })

    function showDetails(index, status){
        let selected
        switch(status){
            case 1:
                selected = benutzt.value[index]
                break
            case 2:
                selected = werkstatt.value[index]
                break
            case 3:
                selected = bereitschafft.value[index]
                break
            case 4:
                selected = bestellt.value[index]
                break
        }

        router.push('/details/' + selected.id)
    }

    function addFahrzeug(){
        addButton.value = "addButton"
        addMode.value = ""
    }

    function createFahrzeug(){
        addButton.value = ""
        addMode.value = "addMode"


        let request = new XMLHttpRequest();
        request.open("POST", "/api/fahrzeug");
        request.setRequestHeader("Authorization", "Bearer " + tokenStore.token)
        request.setRequestHeader("Content-Type", "application/json");
        console.log(fahrzeug.value)
        request.send(JSON.stringify(fahrzeug.value));
        request.onload = () => 
        {
            console.log(request.status)
            if(request.status === 200)
            {
                getData();
                //router.push("/overview")
            }
            else if (request.status === 401) {
                tokenStore.token = ""
                router.push("/login")
            }
            else
            {
                console.log("error in request " + request.status)
                //data.value = ["error in request"];
            }
        }
    }
        
</script>

<template>
    <div :class="InterfaceKlassen">
    </div>
    <div v-if="data != ''" :class="FahrzeugKlassen" class="container column stretch">
        <div class="Status" v-if="benutzt != ''">
            <div class="title">
                <b>Benutzt</b>
            </div>
            <div class="Fahrzeuge">
                <div class="Fahrzeug container" v-for="(value,index) in benutzt" :key="value.kennzeichen" @click="showDetails(index,1)">
                    <FahrzeugFenster :fahrzeug="value"></FahrzeugFenster>
                </div>
            </div>
        </div>
        <div class="Status" v-if="werkstatt != ''">
            <div class="title">
                <b>Werkstatt</b>
            </div>
            <div class="Fahrzeuge">
                <div class="Fahrzeug container" v-for="(value,index) in werkstatt" :key="value.kennzeichen" @click="showDetails(index,2)">
                <FahrzeugFenster :fahrzeug="value"></FahrzeugFenster>
                </div>
            </div>
            
        </div>
        <div class="Status" v-if="bereitschafft != ''">
            <div class="title">
                <b>Bereitschafft</b>
            </div>
            <div class="Fahrzeuge">
                <div class="Fahrzeug container" v-for="(value,index) in bereitschafft" :key="value.kennzeichen" @click="showDetails(index,3)">
                <FahrzeugFenster :fahrzeug="value"></FahrzeugFenster>
                </div>
            </div>
            
        </div>
        <div class="Status" v-if="bestellt != ''">
            <div class="title">
                <b>Bestellt</b>
            </div >
            <div class="Fahrzeuge">
                <div class="Fahrzeug container" v-for="(value,index) in bestellt" :key="value.kennzeichen" @click="showDetails(index,4)">
                    <FahrzeugFenster :fahrzeug="value"></FahrzeugFenster>
                </div>
            </div>
            
        </div>
    </div>
    <div v-else>
        data is missing
    </div>
    <div class="center">
        <button @click="addFahrzeug" :class="addButton" class="eingabe">neues Fahrzeug</button>
    </div>
    <div :class="addMode" class="eingabe">
            <div >
                <table>
                    <tr>
                        <td>
                            <label for="marke">Marke</label>
                        </td>
                        <td>
                            <input id="marke" type="text" v-model="fahrzeug.marke">
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label for="kennzeichen">Kennzeichen</label>
                        </td>
                        <td>
                            <input id="kennzeichen" type="text" v-model="fahrzeug.kennzeichen">
                        </td> 
                    </tr>
                    <tr>
                        <td>
                            <label for="status">Status</label>
                        </td>
                        <td>
                            <select v-model="fahrzeug.status">
                                <option value="benutzung">Benutzt</option>
                                <option value="werkstatt">Werkstatt</option>
                                <option value="bestellt">Bestellt</option>
                                <option value="bereitschafft">Bereitschafft</option>
                            </select>
                        </td>
                    </tr>
                </table>
                <button @click="createFahrzeug" class="speichernKnopf">Speichern</button>
            </div>
        </div>
</template>
