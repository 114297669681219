<script setup>
  //const route = useRoute()
  //import Vue from 'vue'
  import './styles.scss'
  import { useTokenStore} from '@/tokenStore'
  import {watch,ref} from "vue"


  const tokenStore = useTokenStore()

  const token = ref('')
  const selection = ref(["selected","",""])
  const selected = ref(0);

  watch(tokenStore.token, (newX) => {
    token.value = {newX}
  })

  function logout(){
    tokenStore.token = ""
    selectPosition(0)
  }

  function selectPosition(index){
    if (tokenStore.token != ""){
      selection.value[selected.value] = ""
      selection.value[index] ="selected"
      selected.value = index
    } else{
      selection.value[selected.value] = ""
      selection.value[0] ="selected"
      selected.value = 0
    }

}

</script>

<template>
  <div id="app">
    <div class="navbar">
      <div class="navoptions">
        <RouterLink v-if="tokenStore.token.value === ''" to='/login' :class="selection[0]" @click="selectPosition(0)">Login</RouterLink>
        <RouterLink v-else to="/login" @click="logout" :class="selection[0]" >Logout</RouterLink>
        <RouterLink to="/overview" :class="selection[1]" @click="selectPosition(1)">Fahrzeug Übersicht</RouterLink>
        <RouterLink to="/users" :class="selection[2]" @click="selectPosition(2)">Benutzer</RouterLink>
    </div>
      <div class="filler"></div>
    </div>
    
    <br />
    <br />
  </div>
  <div class="container column stretch">
    <RouterView @selection="selectPosition"></RouterView>
  </div>
</template>