import recordTable from '@/components/TestAnwendung/RecordTable.vue'
import newRecord from '@/components/TestAnwendung/NewRecord.vue'
import UpdateRecord from '@/components/TestAnwendung/UpdateRecord.vue'
import loginPage from './loginPage.vue'
import FahrzeugÜbersicht from './components/Fahrzeugverwaltung/Fahrzeug_Übersicht.vue'
import FahrzeugDetails from './components/Fahrzeugverwaltung/FahrzeugDetails.vue'
import BenutzerVerwaltung from './components/Fahrzeugverwaltung/BenutzerVerwaltung.vue'
import { createWebHistory, createRouter } from 'vue-router';
import { useTokenStore} from '@/tokenStore'

const routes = [
  {path:'/overview', name:"overwiev" , meta: { title: 'Übersicht' }, component: FahrzeugÜbersicht},
  {path:'/details/:fahrzeugid', name:"details" , meta: { title: 'Fahrzeugdetails' }, component: FahrzeugDetails, children:[
    {path:"", name:"dataRecord", component:recordTable},
    {path:"", name:"newRecord", component:newRecord},
    {path:"", name:"updateRecord", component:UpdateRecord}
    ]},
  {path:'/users', name:"user" , meta: { title: 'Benutzerverwaltung' }, component: BenutzerVerwaltung, children:[
    {path:"", name:"userTable", component:recordTable},
    {path:"", name:"newUser", component:newRecord},
    {path:"", name:"updateUser", component:UpdateRecord}
    ]},
  {path:'/login',name:"login" , meta: { title: 'Login' }, component: loginPage}
  ]

const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
  })
  
  // eslint-disable-next-line
  router.beforeEach(async (to, from) => {
    const tokenStore = useTokenStore()
    if (
      // make sure the user is authenticated
      tokenStore.token === "" &&
      // ❗️ Avoid an infinite redirect
      to.name !== 'login'
    ) {
      // redirect the user to the login page
      return { name: 'login' }
    }
  })

  router.beforeEach((to, from, next) => {
    //console.log(to);
    document.title = to.meta.title;
    next();
  });

export default router