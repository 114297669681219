<script setup>
    import GetDataButtonVue from '../Buttons/GetDataButton.vue';
    import DeleteRecordButtonVue from '../Buttons/DeleteRecordButton.vue';
    import CreateRecordButtonVue from '../Buttons/CreateRecordButton.vue';
    import UpdateRecordButtonVue from '../Buttons/UpdateRecordButton.vue';
    import {ref,provide,onMounted,defineProps,defineExpose,inject} from "vue";
    import router from '@/router';
    //Variablen welche zur generalisierung gliefert werden müssen

    const props = defineProps({modelname:String,id:String})

    const data = ref({});
    provide('data',data);
    const selected = ref("");
    provide('selected',selected)

    const fahrzeugID = inject("fahrzeugID")
    
    const modelname = inject('modelname')
    const getData = ref(null);
    const newData = ref(null);
    const updateData = ref(null);
    router.push('dataRecord')


    function extractData(index)
    {
        selected.value = data.value[index];
    }

    function refresh(){
        getData.value.getData()
        selected.value = data.value[0];
    }

    function interfaceNew(){
        router.push({name:"newRecord"})
    }

    function navigateGet(){
        router.push({name:"dataRecord"});
    }

    function navigateUpdate(){
        router.push({name:"updateRecord"});
    }

    function addObject(object){
        object.value.fahrzeugID = fahrzeugID
        newData.value.setData(object)
        refresh();
    }

    function updateObject(object){
        updateData.value.updateData(object)
        refresh();
    }

    function clearSelection(){
        selected.value = ""
    }

    defineExpose({
        refresh,
        clearSelection
    })

    onMounted(() => {
        refresh()
    })
    
</script>

<template>
    <div class="menue">
        <div class="crudButtons">
            <GetDataButtonVue      ref="getData" @navigate="navigateGet" :id="props.id" >Aktualisieren</GetDataButtonVue>
            <CreateRecordButtonVue ref="newData" @new="interfaceNew" >{{modelname}} Hinzufügen</CreateRecordButtonVue>
            <UpdateRecordButtonVue ref="updateData" @refresh="refresh()" @update="navigateUpdate">Update {{modelname}}</UpdateRecordButtonVue>
            <DeleteRecordButtonVue @refresh="refresh()">Lösche {{modelname}}</DeleteRecordButtonVue>
        </div>
        <div class="center">
            <RouterView @refresh="refresh" @selection="extractData" @creation="addObject" @update="updateObject" ></RouterView>
        </div>
    </div>
</template>
