<script setup>
import { ref,watch,provide,onMounted } from 'vue'
import { useTokenStore} from '@/tokenStore'
import { useUserStore} from '@/userStore'
import GetDataButtonVue from "@/components/Buttons/GetDataButton.vue";
import DeleteRecordButtonVue from "@/components/Buttons/DeleteRecordButton.vue";
import CreateRecordButtonVue from "@/components/Buttons/CreateRecordButton.vue";
import UpdateRecordButtonVue from "@/components/Buttons/UpdateRecordButton.vue";
import router from '@/router';

const tokenStore = useTokenStore()
const userStore = useUserStore()
const roles = ref("")

const api = ref("user")
provide("api", api);

const selected = ref("");
provide('selected',selected)

const data = ref({});
provide('data',data)

const getData = ref(null)
const newData = ref(null)
const updateData = ref(null)

const new1 = ref("")
const new2 = ref("")
const username = ref(userStore.username)
const oldPassword = ref("")

router.push({name:'userTable'})

function getRoles(){
    let request = new XMLHttpRequest();
        request.open("GET", "/api/login/roles");
        request.setRequestHeader("Content-Type", "application/json");
        request.setRequestHeader("Authorization", "Bearer " + tokenStore.token)
        request.send();
        request.onload = () => 
        {
            if(request.status === 200 )
            {
                console.log(request.response)
                roles.value = JSON.parse(request.response).roles
                // eslint-disable-next-line
                if(roles.value.includes('admin')){
                    refresh()
                }
            }
            else
            {
                alert("Error: Das Passwort ist falsch.")
            }
        }
}


function changePasword(){
    if (new1.value == new2.value){
        let request = new XMLHttpRequest();
        request.open("POST", "/api/login/password");
        request.setRequestHeader("Content-Type", "application/json");
        request.setRequestHeader("Authorization", "Bearer " + tokenStore.token)
        request.send(JSON.stringify({oldPassword:oldPassword.value,newPassword:new1.value}));
        request.onload = () => 
        {
            if(request.status === 200 & request.response !== "password change failed")
            {
                console.log(request.response)
                alert("Passwort erfolgreich geändert")
            }
            else
            {
                alert("Error: Das Passwort ist falsch.")
            }
        }
    } else{
        alert("Die neuen Psswörter stimmen nicht überein")
    }
}

function refresh(){
    if(getData.value != null){
        getData.value.getData()
        selected.value = data.value[0];
    }
}

function updateObject(object){
    updateData.value.updateData(object)
    refresh();
}

function addObject(object){
    newData.value.setData(object)
    refresh();
}

function extractData(index){
    selected.value = data.value[index];
}

function interfaceNew(){
    router.push({name:"newUser"})
}

function navigateGet(){
    router.push({name:"userTable"})
}

function navigateUpdate(){
    router.push({name:"updateUser"})
}
// eslint-disable-next-line
watch(getData, (newGetdate) => {
    refresh()
})

onMounted(() => {
    getRoles()
})
</script>

<template>
<div class="center">
    <div class="container"><label>Benutzername:</label> <label>{{ username }}</label></div>
    <br>
    <div class="container"><label>Passwort:</label> <input type="password" v-model="oldPassword"></div>
    <div class="container"><label>Neues Passwort:</label> <input type="password" v-model="new1"></div>
    <div class="container"><label>Neues Passwort:</label> <input type="password" v-model="new2"></div>
    <br>
    <button @click="changePasword">Passwort ändern</button>

    <div v-if="roles.includes('admin')">
        <div class="navigation">
        <div class="filler">
        </div>
    </div>
        <div class="menue">
            <div class="crudButtons">
                <GetDataButtonVue      ref="getData" @navigate="navigateGet" id="" >Aktualisieren</GetDataButtonVue>
                <CreateRecordButtonVue ref="newData" @new="interfaceNew" >User Hinzufügen</CreateRecordButtonVue>
                <UpdateRecordButtonVue ref="updateData" @refresh="refresh()" @update="navigateUpdate">Update User</UpdateRecordButtonVue>
                <DeleteRecordButtonVue @refresh="refresh()">Lösche User</DeleteRecordButtonVue>
            </div>
            <div>
                <RouterView @refresh="refresh" @selection="extractData" @creation="addObject" @update="updateObject" ></RouterView>
            </div>
        </div>
    </div>
</div>

</template>