<script setup>

import {ref,onMounted,nextTick,provide} from "vue"
import {useTokenStore} from '@/tokenStore'
import {useRoute} from "vue-router";
import RecordMenue from '../TestAnwendung/RecordMenue.vue';
import FileUpload from './components/UploadFile.vue';
import router from "@/router";

const route = useRoute();
const readmode = ref(true)
const fahrzeugid = route.params.fahrzeugid;
const navigationSelection = ref(["selected","","","",""])
const currentNavigation = ref(0)
const api = ref("rechnung")
const modelname = ref("Rechnung")
const editButtonClasses = ref("")
const saveEditButtonClasses = ref("inactive")
var originalFahrzeug = {}
var originalKilometersteande = {}

provide('api', api)
provide('modelname', modelname)
provide("fahrzeugID", fahrzeugid)

const kilometerstände = ref('')
const fahrzeug = ref('')
const inputClasses = ref(["readonly"])
const tokenStore = useTokenStore()
const child = ref()

function getKilometerstände(){
        let request = new XMLHttpRequest();
        request.open("GET", "/api/fahrzeug/kilometerstaende/" + fahrzeugid);
        request.setRequestHeader("Authorization", "Bearer " + tokenStore.token)
        request.send();
        request.onload = () => 
        {
            if(request.status === 200)
            {
                kilometerstände.value = JSON.parse(request.response);
                console.log(kilometerstände.value)
                originalKilometersteande = JSON.parse(request.response)
            }
            else if (request.status === 401) 
            {
                tokenStore.token = ""
                router.push("/login")
            }
            else
            {
                alert("Error in request")
            }
        }
}

function getFahrzeug(){    
        let request = new XMLHttpRequest();
        request.open("GET", "/api/fahrzeug/" + fahrzeugid);
        request.setRequestHeader("Authorization", "Bearer " + tokenStore.token)
        request.send();
        request.onload = () => 
        {
            if(request.status === 200)
            {
                fahrzeug.value = JSON.parse(request.response);
                originalFahrzeug = JSON.parse(request.response)
            }
            else if (request.status === 401) 
            {
                tokenStore.token = ""
                router.push("/login")
            }
            else
            {
                alert("Error in request")
            }
        }
}

function showRechnungen(index){
    changeActiveNavigation(index)
    modelname.value = "Rechnung"
    api.value = "rechnung"
    refreshChild()
}
function showVerträge(index){
    changeActiveNavigation(index)
    modelname.value = "Vertrag"
    api.value = "vertrag"
    refreshChild()
}
function showVersicherungen(index){
    changeActiveNavigation(index)
    modelname.value = "Versicherung"
    api.value = "versicherung"
    refreshChild()
}
function showReifen(index){
    changeActiveNavigation(index)
    modelname.value = "Reifen"
    api.value = "reifen"
    refreshChild()
}
function showUnfälle(index){
    changeActiveNavigation(index)
    modelname.value = "Unfall"
    api.value = "unfall"
    refreshChild()
}

function refreshChild(){
    nextTick(() => {
            child.value.refresh()
            child.value.clearSelection()
        })
}

function changeActiveNavigation(index){
    navigationSelection.value[currentNavigation.value] = ""
    navigationSelection.value[index] ="selected"
    currentNavigation.value = index
}

onMounted( async () => {

    await getKilometerstände()
    await getFahrzeug()
})

    function activateEditmode(){
        readmode.value = false
        editButtonClasses.value = "inactive"
        saveEditButtonClasses.value = ""

    }
    
    function saveChanges(){
        readmode.value = true;
        editButtonClasses.value = ""
        saveEditButtonClasses.value = "inactive"
        Object.assign(originalFahrzeug,fahrzeug.value)
        originalKilometersteande = structuredClone(kilometerstände.value)

        updateDB();
    }

    function deleteFahrzeug(){

        if (confirm("Sicher das du das Fahrzeug löschen möchtest?")){
            let request = new XMLHttpRequest();
            request.open("DELETE", "/api/fahrzeug/");
            request.setRequestHeader("Authorization", "Bearer " + tokenStore.token)
            request.setRequestHeader("Content-Type", "application/json");
            request.send(JSON.stringify(fahrzeug.value));
            request.onload = () => 
            {
                console.log("Staus: " + request.status)
                if(request.status === 200)
                {
                    console.log(request.response)
                    router.push("/overwiev")
                }
                else if (request.status === 401) 
                {
                    tokenStore.token = ""
                    router.push("/overwiev")
                }
                else
                {
                    alert("Error in request")
                }
            }
        }
    }

    function updateDB(){
        let request = new XMLHttpRequest();
        request.open("PUT", "/api/fahrzeug/");
        request.setRequestHeader("Authorization", "Bearer " + tokenStore.token)
        request.setRequestHeader("Content-Type", "application/json");
        fahrzeug.value.kilometerstaende = kilometerstände.value
        console.log(fahrzeug.value)
        console.log(JSON.stringify(fahrzeug.value))
        request.send(JSON.stringify(fahrzeug.value));
        request.onload = () => 
        {
            if(request.status === 200)
            {
                console.log(request.response)
            }
            else if (request.status === 401) 
            {
                tokenStore.token = ""
                router.push("/login")
            }
            else
            {
                alert("Error in request")
            }
        }
    }

    function revertChanges(){
        readmode.value = true
        editButtonClasses.value = ""
        saveEditButtonClasses.value = "inactive"

        Object.assign(fahrzeug.value,originalFahrzeug)
        kilometerstände.value = structuredClone(originalKilometersteande)
    }

    function addkilometerstaende(){
        kilometerstände.value[kilometerstände.value.length] = {datum:"",kilometerstand:"",id:fahrzeug.value.id}
    }

    function removekilometerstaende(){
        kilometerstände.value.pop()
    }

</script>

<template>
    <div class="stretch">
    <div v-if="fahrzeug != ''" class="center">
        <div class="container">
            <button @click="activateEditmode()" :class="editButtonClasses">Editiere Daten</button>
            <button @click="saveChanges()" :class="saveEditButtonClasses">Speichere Änderungen</button>
            <button @click="revertChanges()" :class="saveEditButtonClasses">Änderungen zurücksetzten</button>
            <button @click="deleteFahrzeug()">Lösche Fahrzeug</button>
        </div>
        <div class="container kopfdaten">
            <div class="kopfbild">
                <img :src="fahrzeug.bildpfad" alt="Auto">
                <div :class="saveEditButtonClasses">
                    <FileUpload :id="fahrzeugid" type="Fahrzeug" accepted="image/*" folder="fahrzeugbild"></FileUpload>
                </div>
                
                
            </div>
            <div clas="kennwerte">
                <table>
                    <tr>
                        <td>
                            <label for="marke">Marke</label>
                        </td>
                        <td>
                            <input :class="inputClasses" id="marke" :readonly="readmode" type="text" v-model="fahrzeug.marke">
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label for="kennzeichen">Kennzeichen</label>
                        </td>
                        <td>
                            <input :class="inputClasses" id="kennzeichen" :readonly="readmode" type="text" v-model="fahrzeug.kennzeichen">
                        </td> 
                    </tr>
                    <tr>
                        <td>
                            <label for="status">Status</label>
                        </td>
                        <td>
                            <!--<input :class="inputClasses" id="status" :readonly="readmode" type="text" v-model="fahrzeug.status"> -->
                            <select v-model="fahrzeug.status" :disabled="readmode">
                                <option value="benutzung">Benutzt</option>
                                <option value="werkstatt">Werkstatt</option>
                                <option value="bestellt">Bestellt</option>
                                <option value="bereitschafft">Bereitschafft</option>
                            </select>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div class="center">
            <table v-if="kilometerstände.length != 0">
                <tr><th><label>kilometerstand</label></th><th><label>Datum</label></th></tr>
                <tr v-for="kilometerstaende in kilometerstände" :key="kilometerstaende.datum">
                    <td><input v-model="kilometerstaende.kilometerstand" type="text" :class="inputClasses" :readonly="readmode"></td>
                    <td><input  v-model="kilometerstaende.datum" type="date" :class="inputClasses" :readonly="readmode"></td>
                </tr>
                <tr :class="saveEditButtonClasses"><button @click="addkilometerstaende()">+</button><button @click="removekilometerstaende()">-</button></tr>
                
            </table>
            
        </div>
    <div class="navigation">
        <a :class="navigationSelection[0]" @click="showRechnungen(0)">Rechnungen</a>
        <a :class="navigationSelection[1]" @click="showReifen(1)">Reifen</a>
        <a :class="navigationSelection[2]" @click="showVerträge(2)">Verträge</a>
        <a :class="navigationSelection[3]" @click="showVersicherungen(3)">Versichernungen</a>
        <a :class="navigationSelection[4]" @click="showUnfälle(4)">Unfälle</a>
        <div class="filler">
        </div>
    </div>
    
        <RecordMenue :id="fahrzeugid" ref="child"></RecordMenue>
    </div>
</template>