<script setup>
    import {ref,defineEmits,onMounted,inject} from 'vue'
    import {useTokenStore} from '@/tokenStore'
    //import FileList from "@/components/Fahrzeugverwaltung/components/FileList.vue"
    
    //const dateiManagment = ref(null)
    const model = ref({})
    const newObject  = ref({})
    const api = inject('api')
    const modelname = inject('modelname')

    const emit = defineEmits(['creation'])

    function getData()
    {
        const tokenStore = useTokenStore()

        

        let request = new XMLHttpRequest();
        request.open("GET", "/api/" + api.value + "/dummy");
        request.setRequestHeader("Authorization", "Bearer " + tokenStore.token)
        request.send();
        request.onload = () => 
        {
            if(request.status === 200)
            {
                model.value = JSON.parse(request.response);
                console.log("model.value")
                console.log(model.value)
                for (const key in model.value) {
                    if(key != "dateien" && key != "id"){
                        if(key.includes("betrag") || key.includes("rate")){
                        newObject.value[key] = 0
                        } else if(key.includes("datum") === false){
                            newObject.value[key] = key
                        }
                    }
                    
                }
                console.log(newObject.value)
                model.value.dateien = {name:""}
            }
            else
            {
                //console.log(request.status)
                model.value = ["error in request"];
            }
        }
    }

    function createObject(){
        console.log(newObject.value)
        /*if(dateiManagment.value != null && dateiManagment.value.dateien != undefined){
            newObject.value.dateien = dateiManagment.value.dateien
        }*/
        emit('creation', newObject)
    }
    

    onMounted(() => {
        getData();
    })
</script>

<template>
    <div class="input column center">
        <div>
            <table>
                    <tr v-for="(value,key) in model" :key="key">
                        <div v-if="key != 'id' && key != 'dateien'">
                            <div v-if="key.includes('datum') || key.includes('zeit')" class="container">
                                <td><label  class="label" > {{key}}:</label></td>
                                <td><input v-model="newObject[key]" type="date"></td>
                            </div>
                            <div v-else-if="key.includes('betrag') || key.includes('rate')" class="container">
                                <td><label  class="label" > {{key}}:</label></td>
                                <td><input v-model="newObject[key]" type="number"></td>
                            </div>
                            <div v-else class="container">
                                <td><label  class="label" > {{key}}:</label></td>
                                <td><input v-model="newObject[key]" type="text"></td>
                            </div>
                        </div>
                    </tr>

                    <!--- <tr><FileList ref="dateiManagment"></FileList></tr> ---->
            </table>
        </div>
        <button @click="createObject">Create {{modelname}}</button>
    </div>
</template>
