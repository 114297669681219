<script setup>
    import {defineEmits,defineExpose,inject} from "vue"
    import { useTokenStore} from '@/tokenStore'


    const emit = defineEmits(['refresh','update'])
    const tokenStore = useTokenStore()
    const api = inject('api')
    
    function updateData(object)
    {
        
        console.log("update: " + JSON.stringify(object));
        let request = new XMLHttpRequest();
        request.open("PUT", "/api/" + api.value);
        request.setRequestHeader("Content-Type", "application/json");
        request.setRequestHeader("Authorization", "Bearer " + tokenStore.token);
        request.send(JSON.stringify(object));
        

        request.onload = () => 
        {
            if(request.status === 200)
            {
                console.log(request.response);
                emit('refresh');
            }
        }
    }

    defineExpose({
        updateData
    })
</script>

<template>
    <button @click="$emit('update')">
        <slot></slot>
    </button>
</template>