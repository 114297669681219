<script setup>
    import {defineProps,defineEmits,ref,defineExpose} from 'vue';
    import tableField from "./TableField.vue";
    import FileList from "./FileList.vue";

    const props = defineProps({id:String,dateien:Object,index:Number,object:Object})
    const emit = defineEmits(['selection','refresh'])
    const checked = ref();


    function check(){
        checked.value = true;
        emit('selection',props.index);
    }

    function uncheck(){
        checked.value = false;
    }

    function isFile(key){
        if( key != "dateien"){
            return false
        } else {
            return true
        }
    }

    function isNotPassword(key){
        if( key != "password"){
            return true
        } else {
            return false
        }
    }

    defineExpose({
        uncheck
    })

</script>


<template>
    <tr @click="check()">
        <tableField><input type="radio" name="selection" @click="emit('selection',props.index)" :checked="checked"></tableField>
        <tableField>{{props.index + 1}}</tableField>
        <tableField v-for="(value,key) in object" :key="value" :keyname="key">
            <div v-if="isNotPassword(key)">
                <div v-if="isFile(key)">
                <FileList @refresh="$emit('refresh')" :id="props.id" :dateien="dateien" ></FileList>
                </div>
                <div v-else>
                    {{value}}
                </div>
            </div>
        </tableField>
    </tr>
</template>