<script setup>
    
    import {defineProps,inject,defineExpose,defineEmits} from "vue"
    import { useTokenStore} from '@/tokenStore'
    
    const emit = defineEmits(['navigate'])

    const props = defineProps({id:String})
    const data = inject('data')
    const api = inject('api')
    
    function getData()
    {
        const tokenStore = useTokenStore()

        let request = new XMLHttpRequest();
        request.open("GET", "/api/" + api.value + "/" + props.id);
        request.setRequestHeader("Authorization", "Bearer " + tokenStore.token)
        request.send();
        request.onload = () => 
        {
            if(request.status === 200)
            {
                data.value = JSON.parse(request.response);
            }
            else
            {
                console.log(request.status)
                data.value = ["error in request"];
            }
            emit('navigate')
        }
    }

    defineExpose({
        getData
    })

</script>

<template>
    <button @click="getData()" ><slot></slot></button>
</template>